type DOMHighResTimeStamp = number;

export const getScreenRefreshRate = () =>
	new Promise<number>(resolve => {
		const times: DOMHighResTimeStamp[] = [];
		const framesToCount = 10;

		const onAnimationFrame = (time: DOMHighResTimeStamp) => {
			times.push(time);

			if (times.length > framesToCount) {
				const fps = Math.floor((1000 * framesToCount) / (times[times.length - 1] - times[0]));

				resolve(fps);

				return;
			}

			requestAnimationFrame(onAnimationFrame);
		};

		requestAnimationFrame(onAnimationFrame);
	});
