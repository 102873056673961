import { getAppLaunchCounter } from './utils/app-launch-counter';
import { getAppSession } from './utils/app-session';
import { getBrowserInfo } from './utils/browser';
import { BatteryInfo, getBrowserBatteryInfo } from './utils/browser-battery';
import { getBrowsrrMemoryInfo, MemoryInfo } from './utils/browser-memory';
import { getBrowserPermissions } from './utils/browser-permissions';
import { getDeviceId } from './utils/device-id';
import { getEnvironment } from './utils/environment';
import { getNetworkInfo, NetworkInfo } from './utils/network-info';
import { getScreenRefreshRate } from './utils/screen-info';
import { referrerData } from './utils/window-referrer';

interface AppState {
	appLanguage: string;
	device_id: string;
	networkState?: NetworkInfo;
	batteryState?: BatteryInfo;
	memoryState?: MemoryInfo;
}

const getAppState = async (appLanguage: string): Promise<AppState | undefined> => {
	const batteryInfo = await getBrowserBatteryInfo();
	const memoryState = getBrowsrrMemoryInfo();
	const networkState = getNetworkInfo();
	const screenRefreshRate = await getScreenRefreshRate();
	const browserPermissions = await getBrowserPermissions();
	const referrer = referrerData();
	const environment = getEnvironment();
	const appLaunchCounter = getAppLaunchCounter();
	const browserDetails = getBrowserInfo();
	const appSession = getAppSession();
	const device_id = getDeviceId();

	return {
		appLanguage,
		device_id,
		...(networkState && { networkState }),
		...(batteryInfo && { batteryState: batteryInfo }),
		...(memoryState && { memoryState }),
		...(screenRefreshRate && { screenRefreshRate }),
		...(browserPermissions && { browserPermissions }),
		...(referrer && { referrer }),
		...(environment && { environment }),
		...(appLaunchCounter && { appLaunchCounter }),
		...(browserDetails && { browserDetails }),
		...(appSession && { appSession }),
	};
};

export { getAppState, getNetworkInfo };
