import { APP_LAUNCH_COUNTER_KEY, APP_LAUNCH_COUNTER_TIME_KEY } from '../constants';
import { Storage } from './storage';

const getAppLaunchCounter = () => {
	const localStorage = Storage.getSyncLocalStorage();

	if (Storage.isLocalStorageAvailable() === false) {
		return {
			value: 0,
			lastSeen: Date.now(),
		};
	}

	const counterValue = localStorage.readSync(APP_LAUNCH_COUNTER_KEY);
	const counterLastSeen = localStorage.readSync(APP_LAUNCH_COUNTER_TIME_KEY);

	if (counterValue && counterLastSeen) {
		return {
			value: Number(counterValue),
			lastSeen: Number(counterLastSeen),
		};
	}

	const defaultValue = 0;
	const defaultLastSeen = Date.now();

	localStorage.updateSync(APP_LAUNCH_COUNTER_KEY, defaultValue.toString());
	localStorage.updateSync(APP_LAUNCH_COUNTER_TIME_KEY, defaultLastSeen.toString());

	return {
		value: defaultValue,
		lastSeen: defaultLastSeen,
	};
};

const incrementAppLaunchCounter = () => {
	const { lastSeen, value } = getAppLaunchCounter();
	const localStorage = Storage.getSyncLocalStorage();

	if (Storage.isLocalStorageAvailable() === false)
		return {
			value,
			lastSeen,
		};

	if (Date.now() - lastSeen < 10000) {
		return {
			value,
			lastSeen,
		};
	}

	const newCount = value + 1;
	localStorage.updateSync(APP_LAUNCH_COUNTER_KEY, newCount.toString());
	localStorage.updateSync(APP_LAUNCH_COUNTER_TIME_KEY, Date.now().toString());

	return {
		value: newCount,
		lastSeen: Date.now(),
	};
};

export { getAppLaunchCounter, incrementAppLaunchCounter };
