const MB_IN_BYTES = 1024 * 1024;
const GB_IN_BYTES = 1024 * 1024 * 1024;

export interface MemoryInfo {
	availableRamMb: number;
	isMemoryWarningRaised: boolean;
	availableDiskSpaceGb: number;
}

export const getBrowsrrMemoryInfo = (): MemoryInfo | undefined => {
	// https://developer.mozilla.org/en-US/docs/Web/API/Performance/memory
	// @ts-ignore
	const memoryInfo = performance.memory;

	if (!memoryInfo || memoryInfo?.jsHeapSizeLimit === 0 || memoryInfo?.usedJSHeapSize === 0) {
		return undefined;
	}

	return {
		availableRamMb: (memoryInfo.jsHeapSizeLimit - memoryInfo.usedJSHeapSize) / MB_IN_BYTES,
		isMemoryWarningRaised: false,
		availableDiskSpaceGb: memoryInfo.jsHeapSizeLimit / GB_IN_BYTES,
	};
};
