export const referrerData = () => {
	const referrer = document.referrer;

	if (!referrer) {
		return undefined;
	}

	const referrerHost = new URL(referrer).hostname;
	const referrerPath = new URL(referrer).pathname;
	return { referrerHost, referrerPath };
};
