//@ts-nocheck

export enum NetworkType {
	NETWORK_TYPE_UNSPECIFIED,
	NETWORK_TYPE_OFFLINE,
	NETWORK_TYPE_2G,
	NETWORK_TYPE_3G,
	NETWORK_TYPE_4G,
}

export interface NetworkInfo {
	isWifiOn: boolean;
	wifiSsid: string;
	isCellularOn: boolean;
	isBluetoothOn: boolean;
	cellularMccMnc: string;
	networkType: NetworkType;
	asn: number;
	networkSpeedKbps: number;
	carrier: string;
}

const getNetworkType = (): { type: NetworkType; value: string } => {
	if (typeof window === 'undefined') {
		return { type: NetworkType.NETWORK_TYPE_UNSPECIFIED, value: 'server-unspecified' };
	}
	const isOffline = !navigator.onLine;
	const effectiveType = navigator.connection?.effectiveType;

	if (isOffline) {
		return { type: NetworkType.NETWORK_TYPE_OFFLINE, value: 'offline' };
	}

	switch (effectiveType) {
		case 'slow-2g':
		case '2g': {
			return { type: NetworkType.NETWORK_TYPE_2G, value: '2g' };
		}

		case '3g': {
			return { type: NetworkType.NETWORK_TYPE_3G, value: '3g' };
		}

		case '4g': {
			return { type: NetworkType.NETWORK_TYPE_4G, value: '4g' };
		}
		default: {
			return { type: NetworkType.NETWORK_TYPE_UNSPECIFIED, value: 'unspecified' };
		}
	}
};

export const getNetworkInfo = (): NetworkInfo | undefined => {
	const isConnectionTypeSupported = !!navigator.connection?.effectiveType || !!navigator.connection?.type;

	if (!isConnectionTypeSupported) {
		return undefined;
	}

	return {
		// TODO: We send false even if the API is unavailable. Revisit this, will require proto change.
		isWifiOn: navigator.connection?.type === 'wifi',
		// wifiSsid as unknown until we find a reliable way to determine
		wifiSsid: 'unknown',
		// TODO: We send false even if the API is unavailable. Revisit this, will require proto change.
		isCellularOn: navigator.connection?.type === 'cellular',
		// isBluetoothOn: non deterministic without showing a prompt to user which will hamper UX https://stackoverflow.com/questions/61890029/web-bluetooth-how-to-check-if-bluetooth-is-enabled-with-javascript
		isBluetoothOn: false,
		// cellularMccMnc as unknown, determining MNC without additional API call not possible
		cellularMccMnc: 'unknown',
		networkType: getNetworkType().type,
		// asn is deprecated, hence added a dummy value
		asn: 0,
		networkSpeedKbps: Math.floor((navigator.connection?.downlink ?? 0) * 125),
		carrier: 'unknown',
	};
};
