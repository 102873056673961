export interface BatteryInfo {
	batteryStrengthPercent: number;
	isBatteryCharging: boolean;
}

export const getBrowserBatteryInfo = async (): Promise<BatteryInfo | undefined> => {
	try {
		// @ts-ignore
		if (!navigator.getBattery) {
			return undefined;
		}

		// @ts-ignore
		const battery = await navigator.getBattery();

		return {
			batteryStrengthPercent: Math.floor(battery.level * 100),
			isBatteryCharging: battery.charging,
		};
	} catch (error) {
		return {
			batteryStrengthPercent: -1,
			isBatteryCharging: false,
		};
	}
};
