'use client';

import { useEffect } from 'react';

import { useZomatoUser } from '@/hooks/useZomatoUser';

import { getAppState } from './app-metric';
import { incrementAppLaunchCounter } from './utils/app-launch-counter';
import { setJumboParams, useTracker } from './useTracker';

export function AppTracker() {
	const { trackEvent } = useTracker();
	const { data, isAuthLoading } = useZomatoUser();

	useEffect(() => {
		if (isAuthLoading) return;

		if (data) {
			if (data.response.user_id) {
				setJumboParams({
					user_id: data.response.user_id,
				});
			}
		}

		getAppState(navigator.language).then(appData => {
			if (appData) {
				trackEvent({
					meta_data: appData,
					event_type: 'PAGE_LAUNCH',
					component: 'APP_LAYOUT',
				});
			}
		});
	}, [data, isAuthLoading]);

	useEffect(() => {
		incrementAppLaunchCounter();
	}, []);

	return null;
}
